// @flow
import React, {type Element} from "react";
import {graphql} from "gatsby";
import {type ContentulPageQueryWithCampaign} from "../types/__generated__/ContentulPageQueryWithCampaign";

import ContactForm from "../components/ContactForm";

type PageProps = {|
  +data: ContentulPageQueryWithCampaign,
|};

const PageCampaign = ({data}: PageProps): Element<any> => {
  const {contentfulPage} = data;

  return (
    <ContactForm
      formHandlerURL={contentfulPage?.formHandler}
      campaign={contentfulPage?.campaignName}
    />
  );
};

export default PageCampaign;

export const query: any = graphql`
  query ContentulPageQueryWithCampaign($slug: String!, $locale: String!) {
    contentfulPage(
      slug: {eq: $slug}
      node_locale: {eq: $locale}
      templateName: {eq: "PageCampaign"}
    ) {
      __typename
      slug
      formHandler
      campaignName
    }
  }
`;
